import {createApp} from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Menubar from 'primevue/menubar';
import Panel from 'primevue/panel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Textarea from 'primevue/textarea';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import ToggleButton from 'primevue/togglebutton';
import ProgressBar from 'primevue/progressbar';


//import 'primevue/resources/themes/saga-blue/theme.css'      //theme
import 'primevue/resources/themes/mdc-dark-indigo/theme.css'
import 'primevue/resources/primevue.min.css'                //core css
import 'primeicons/primeicons.css'                          //icons
import 'primeflex/primeflex.css';



const app = createApp(App)

app.use(PrimeVue)
app.use(ToastService);
app.component('Toast', Toast)
app.component('InputText', InputText)
app.component('Button', Button)
app.component('Menubar', Menubar)
app.component('Panel', Panel)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Textarea', Textarea)
app.component('Checkbox', Checkbox)
app.component('Dropdown', Dropdown)
app.component('ToggleButton', ToggleButton);
app.component('ProgressBar', ProgressBar)


app.mount('#app')